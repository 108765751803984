import { Box } from '@wh-components/core/Box/Box'
import { Button } from '@wh-components/core/Button/Button'
import ArrowtinyDown from '@wh-components/icons/ArrowtinyDown'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import { NavigatorLabel } from '../../../../common/common/Navigators/NavigatorLabel'
import PersonalizationStar from '@wh-components/icons/PersonalizationStar'

type BubbleNavigatorButtonProps = {
    id: string
    label: string
    hasSelectedValues?: boolean
    modalOpen: boolean
    openModal: () => void
    personalized?: boolean | undefined
    taggingData: TaggingData
}

export const BubbleNavigatorButton: FunctionComponent<BubbleNavigatorButtonProps> = ({
    id,
    label,
    hasSelectedValues,
    modalOpen,
    openModal,
    personalized,
    taggingData,
}) => (
    <Box paddingVertical={{ tablet: 'xs' }}>
        <Button
            onClick={() => {
                if (!modalOpen) {
                    callActionEvent('search_result_list_bubbles', taggingData, { label: label })
                }
                openModal()
            }}
            variant="outline"
            color={hasSelectedValues ? 'primary' : 'complimentary'}
            size={{ phone: 'small', tablet: 'medium' }}
            testId={`bubble-navigator-button-${id}`}
        >
            <Box minWidth={{ tablet: '100px' }} display="flex" justifyContent="space-between" alignItems="center">
                {personalized && <PersonalizationStar size="1.5em" marginRight="xs" />}
                <NavigatorLabel
                    truncate={true}
                    color={hasSelectedValues ? 'palette.primary.main' : 'palette.verydarkgrey'}
                    fontWeight={hasSelectedValues ? 'bold' : 'regular'}
                    marginRight={{ phone: 's', tablet: 'l' }}
                >
                    {label}
                </NavigatorLabel>
                <ArrowtinyDown
                    color="inherit"
                    css={css`
                        transition: transform 250ms ease-in-out;
                        transform: ${modalOpen ? 'rotate(180deg)' : ''};
                    `}
                />
            </Box>
        </Button>
    </Box>
)
